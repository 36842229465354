import { IStrategy } from '@navix/utils/adapter'
import { User } from '../domain/user.model'
import { GetTenantUsersRestResponse } from '../domain/get-tenant-users.response'

export class FromGetTenantUsersResponse
  implements IStrategy<GetTenantUsersRestResponse, User[]>
{
  convert = ({ records }: GetTenantUsersRestResponse) =>
    records.map(
      tenantUser =>
        ({
          id: tenantUser.uuid,
          email: tenantUser.email
        }) as User
    )
}
