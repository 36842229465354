import { IStrategy } from '@navix/utils/adapter'
import { User } from '../domain/user.model'
import { UserForm } from '../domain/user-form.model'
import { UpdateUserRequestFormData } from '../domain/update-user.request'

export class ToUpdateUserRequest
  implements IStrategy<User | UserForm, UpdateUserRequestFormData>
{
  convert(
    user: UserForm & Pick<User, 'id' | 'title'>
  ): UpdateUserRequestFormData {
    const formData: UpdateUserRequestFormData = []
    formData.push({ key: 'UserGuid', value: user.id })
    formData.push({ key: 'Email', value: user.email })
    formData.push({ key: 'FirstName', value: user.firstName })
    formData.push({ key: 'LastName', value: user.lastName })
    formData.push({ key: 'Title', value: user.title ?? '' })
    formData.push({
      key: 'TimeZoneId',
      value: (user.timezone as number).toString()
    })
    formData.push({
      key: 'AllowAllTenantsApiAccess',
      value: user.allowAllTenant ? 'true' : 'false'
    })
    formData.push({ key: 'IsActive', value: `${user.isActive}` })
    formData.push({
      key: 'UserClass',
      value: (user.userClass as number).toString()
    })
    if (user.phoneNumber)
      formData.push({ key: 'PhoneNumber', value: user.phoneNumber })
    if (user.avatar) {
      formData.push({ key: 'AvatarContent', value: user.avatar as File })
      formData.push({ key: 'ShouldUpdateAvatar', value: 'true' })
    } else {
      formData.push({ key: 'ShouldUpdateAvatar', value: 'false' })
    }

    for (const roleId of user.roles) {
      formData.push({ key: 'Roles', value: roleId.toString() })
    }

    for (const tenantId of user.tenants) {
      formData.push({ key: 'Tenants', value: tenantId.toString() })
    }
    return formData
  }
}
