export enum AsyncOperations {
  add = 'add',
  update = 'update',
  getDetails = 'getDetails',
  resetPassword = 'resetPassword',
  generateApiKey = 'generateApiKey',
  getAll = 'getAll'
}

export type UsersLoading = {
  [key in AsyncOperations]: boolean
}
