import { createFeatureSelector, createSelector } from '@ngrx/store'
import { USERS_FEATURE_KEY, UsersState, usersAdapter } from './users.reducer'

export const selectUsersState =
  createFeatureSelector<UsersState>(USERS_FEATURE_KEY)

const { selectAll, selectEntities } = usersAdapter.getSelectors()

export const selectAllUsers = createSelector(
  selectUsersState,
  (state: UsersState) => selectAll(state)
)

export const selectUsersEntities = createSelector(
  selectUsersState,
  (state: UsersState) => selectEntities(state)
)

export const selectSelectedId = createSelector(
  selectUsersState,
  (state: UsersState) => state.selectedId
)

export const selectTotalCount = createSelector(
  selectUsersState,
  (state: UsersState) => state.totalCount
)

export const selectLoading = createSelector(
  selectUsersState,
  (state: UsersState) => state.loading
)

export const selectEntity = createSelector(
  selectUsersEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
)

export const selecFilters = createSelector(
  selectUsersState,
  (state: UsersState) => state.filters
)
