import { Injectable, inject } from '@angular/core'
import { Store, select } from '@ngrx/store'

import { firstValueFrom } from 'rxjs'
import { DataFilters } from '../domain/data-filters.model'
import { UserForm } from '../domain/user-form.model'
import { AsyncOperations } from '../domain/users-loading.model'
import { usersActions } from './users.actions'
import * as UsersSelectors from './users.selectors'

@Injectable()
export class UsersFacade {
  private readonly store: Store = inject(Store)

  allUsers$ = this.store.pipe(select(UsersSelectors.selectAllUsers))

  selectedUser$ = this.store.pipe(select(UsersSelectors.selectEntity))
  totalCount$ = this.store.pipe(select(UsersSelectors.selectTotalCount))

  loading$ = this.store.pipe(select(UsersSelectors.selectLoading))
  loading = this.store.selectSignal(UsersSelectors.selectLoading)
  filters$ = this.store.pipe(select(UsersSelectors.selecFilters))

  loadUsers(filters: DataFilters) {
    this.store.dispatch(usersActions.loadUsers({ filters }))
  }

  loadTenantUsers(filters: DataFilters) {
    this.store.dispatch(usersActions.loadTenantUsers({ filters }))
  }

  selectUser(userId: string) {
    this.store.dispatch(usersActions.loadUserDetails({ userId }))
  }

  addUser(user: UserForm) {
    this.store.dispatch(usersActions.addUser({ user }))
  }

  async updateUser(userForm: UserForm) {
    const user = await firstValueFrom(this.selectedUser$)

    this.store.dispatch(
      usersActions.updateUser({
        user: { ...userForm, id: user?.id as string, title: user?.title }
      })
    )
  }

  resetPassword(userId: string) {
    this.store.dispatch(usersActions.resetPassword({ userId }))
  }

  startLoading(operation: AsyncOperations) {
    this.store.dispatch(
      usersActions.setLoading({
        operation,
        loading: true
      })
    )
  }
  endLoading(operation: AsyncOperations) {
    this.store.dispatch(
      usersActions.setLoading({
        operation,
        loading: false
      })
    )
  }

  async updateListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.filters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(usersActions.setListFilters({ filters: newFilters }))
  }

  patchListFilters(filters: DataFilters) {
    this.store.dispatch(usersActions.patchListFilters({ filters }))
  }

  resetFiltes() {
    this.store.dispatch(usersActions.resetListFilters())
  }

  generateUserApiKey = async () => {
    const user = await firstValueFrom(this.selectedUser$)
    this.store.dispatch(
      usersActions.generateApiKey({ userUuid: <string>user?.id })
    )
  }
}
