import { makeEnvironmentProviders } from '@angular/core'
import { provideState } from '@ngrx/store'
import { USERS_FEATURE_KEY, usersReducer } from '../+state/users.reducer'
import { UsersEffects } from '../+state/users.effects'
import { provideEffects } from '@ngrx/effects'
import { UsersFacade } from '../+state/users.facade'

export function provideUsersFeature() {
  return makeEnvironmentProviders([
    provideEffects(UsersEffects),
    provideState(USERS_FEATURE_KEY, usersReducer),
    UsersFacade
  ])
}
