import { IStrategy } from '@navix/utils/adapter'
import { GetUsersResponse } from '../domain/get-users.response'
import { User } from '../domain/user.model'

export class FromGetUsersResponse
  implements IStrategy<GetUsersResponse, User[]>
{
  convert = ({ value }: GetUsersResponse) =>
    value.map(
      user =>
        ({
          id: user.Uuid,
          secondaryId: user.UserId,
          firstName: user.FirstName,
          lastName: user.LastName,
          isActive: user.IsActive,
          email: user.Email,
          phoneNumber: user.PhoneNumber,
          timezone: user.Timezone,
          createdDate: user.CreatedDate,
          fullName: `${user.FirstName} ${user.LastName}`,
          avatarLetter: user.FirstName ? user.FirstName[0] : user.Email[0],
          withDetails: false,
          rolesName:
            Array.isArray(user.Roles) && user.Roles.length
              ? [user.Roles.sort()[0], user.Roles.sort().slice(1).join(', ')]
              : ['', '']
        }) as User
    )
}
