import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'

import { API_URL, HEADER_API_KEY } from '@navix/utils/tokens'
import { GetUserDetailResponse } from '../domain/get-user-detail.response'
import { GetUsersResponse } from '../domain/get-users.response'

import { OdataParser, QUERY_PARSER } from '@navix/utils/query-parser'
import { AddUserRequestFormData } from '../domain/add-user.request'
import { DataFilters, StatusFilter } from '../domain/data-filters.model'
import { UpdateUserRequestFormData } from '../domain/update-user.request'
import { GetTenantUsersRestResponse } from '../domain/get-tenant-users.response'

@Injectable({ providedIn: 'root' })
export class UsersService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly parser = inject<OdataParser>(QUERY_PARSER, {})

  private readonly apiUsersUrl = `${this.apiUrl}users/` as const
  private readonly apiAuthUrl = `${this.apiUrl}auth/` as const
  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  private filterColumns = ['FirstName', 'LastName', 'Email', 'PhoneNumber']

  getUsers(filters: DataFilters): Observable<GetUsersResponse> {
    const query = this.parser
      .prepare('user')
      .setCount(true)
      .setContainFilterColumns(this.filterColumns)

    if (filters.search) query.setContainFilter(filters.search)
    if (filters.itemsPerPage) query.setTop(filters.itemsPerPage)
    if (filters.itemsPerPage && filters.page)
      query.setSkip(filters.itemsPerPage * (filters.page - 1))
    if (filters.status === StatusFilter.active)
      query.setEqualityFilter('IsActive', true)
    if (filters.status === StatusFilter.inactive)
      query.setEqualityFilter('IsActive', false)

    if (filters.sortBy && filters.sortDirection)
      query.setOrderBy({
        column: filters.sortBy,
        direction: filters.sortDirection
      })

    if (
      filters.tenant &&
      filters.tenant.length > 0 &&
      filters.tenant.find(x => x.id === 0) === undefined
    )
      query.setInPropertyFilterColumns(
        '',
        filters.tenant.map(x => x.id),
        'Tenants/any(t:t',
        ')'
      )

    return this._http.get<GetUsersResponse>(`${this.apiUrl}users-list`, {
      params: query.getQueryParamsAsHttpParams(),
      headers: this.headersWithApiKey
    })
  }

  getTenantUsers(filters: DataFilters): Observable<GetTenantUsersRestResponse> {
    const queryFilters: Record<
      string,
      string | number | boolean | ReadonlyArray<string | number | boolean>
    > = {
      PageNumber: filters.page!,
      PageSize: filters.itemsPerPage!,
      Roles: filters.roles?.map(x => x) ?? [],
      SingleRole: filters.singleRole ?? false
    }

    if (filters.search) queryFilters['Email'] = filters.search

    switch (filters.status) {
      case StatusFilter.active:
        queryFilters['IsActive'] = true
        break
      case StatusFilter.inactive:
        queryFilters['IsActive'] = false
        break
      case StatusFilter.all:
      default:
        break
    }

    const params = new HttpParams({
      fromObject: queryFilters
    })

    return this._http.get<GetTenantUsersRestResponse>(
      `${this.apiAuthUrl}tenants/current/users`,
      {
        params,
        headers: this.headersWithApiKey
      }
    )
  }

  getUserDetail(userId: string): Observable<GetUserDetailResponse> {
    return this._http.get<GetUserDetailResponse>(
      `${this.apiUsersUrl}${userId}/details`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addUser(request: AddUserRequestFormData): Observable<object> {
    const formData = new FormData()
    request.forEach(entry => formData.append(entry.key, entry.value))
    return this._http.post(`${this.apiUsersUrl}users`, formData, {
      headers: { ...this.headersWithApiKey }
    })
  }

  updateUser(request: UpdateUserRequestFormData): Observable<object> {
    const formData = new FormData()
    request.forEach(entry => formData.append(entry.key, entry.value))
    return this._http.put(`${this.apiAuthUrl}users`, formData, {
      headers: { ...this.headersWithApiKey }
    })
  }

  resetPassword(userId: string): Observable<void> {
    return this._http.get<void>(
      `${this.apiAuthUrl}users/${userId}/reset-password`,
      {
        headers: { ...this.headersWithApiKey }
      }
    )
  }

  generateApiKeyAccess = (userUuid: string): Observable<string> =>
    this._http.put<string>(
      `${this.apiAuthUrl}${userUuid}/users/generate-new-api-key-access`,
      null,
      { headers: { ...this.headersWithApiKey }, responseType: 'text' as 'json' }
    )
}
