import { Injectable, inject } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import {
  catchError,
  exhaustMap,
  finalize,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs'
import { UsersService } from '../infrastructure/users.service'
import { usersActions } from './users.actions'

import { Dialog } from '@angular/cdk/dialog'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { CurrentUserFacade } from '@navix/current-user/domain'
import { FromGetUserDetailResponse } from '../adapter/FromGetUserDetailResponse'
import { FromGetUsersResponse } from '../adapter/FromGetUsersResponse'
import { ToAddUserRequest } from '../adapter/ToAddUserRequest'
import { ToUpdateUserRequest } from '../adapter/ToUpdateUserRequest'
import { FromUserAdapter, ToUserAdapter } from '../adapter/UserAdapter'
import { AddUserRequestFormData } from '../domain/add-user.request'
import { UpdateUserRequestFormData } from '../domain/update-user.request'
import { User } from '../domain/user.model'
import { AsyncOperations } from '../domain/users-loading.model'
import {
  ADD_USER_DIALOG_ID,
  RESET_PASSWORD_DIALOG_ID,
  UPDATE_USER_DIALOG_ID,
  USER_GENERATED_API_KEY_DIALOG_ID
} from './users.contants'
import { UsersFacade } from './users.facade'
import { FromGetTenantUsersResponse } from '../adapter/FromGetTenantUsersResponse'
@Injectable({
  providedIn: 'root'
})
export class UsersEffects {
  private toUserAdapter = new ToUserAdapter()
  private fromUserAdapter = new FromUserAdapter()

  private dialog = inject(Dialog)
  private actions$ = inject(Actions)
  private usersService = inject(UsersService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private usersFacade = inject(UsersFacade)
  private currentUserFacade = inject(CurrentUserFacade)

  constructor() {
    this.toUserAdapter.set(FromGetUsersResponse)
    this.toUserAdapter.set(FromGetUserDetailResponse)
    this.toUserAdapter.set(FromGetTenantUsersResponse)
    this.fromUserAdapter.set(ToAddUserRequest)
    this.fromUserAdapter.set(ToUpdateUserRequest)
  }

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.loadUsers),
      tap(() => this.usersFacade.startLoading(AsyncOperations.getAll)),
      switchMap(({ filters }) => {
        //Todo: use specific actions. Create new method on the facade to call this.
        return this.usersService.getUsers(filters)
      }),
      switchMap(res => [
        usersActions.setTotalCount({ count: res['@odata.count'] }),
        usersActions.loadUsersSuccess({
          users: this.toUserAdapter.convert(FromGetUsersResponse, res) as User[]
        }),
        usersActions.setLoading({
          loading: false,
          operation: AsyncOperations.getAll
        })
      ])
    )
  )

  loadTenantUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.loadTenantUsers),
      tap(() => this.usersFacade.startLoading(AsyncOperations.getAll)),
      switchMap(({ filters }) => {
        return this.usersService.getTenantUsers(filters)
      }),
      switchMap(res => [
        usersActions.loadTenantUsersSuccess({
          users: this.toUserAdapter.convert(
            FromGetTenantUsersResponse,
            res
          ) as User[]
        }),
        usersActions.setLoading({
          loading: false,
          operation: AsyncOperations.getAll
        })
      ])
    )
  )

  loadUserDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.loadUserDetails),
      tap(() => this.usersFacade.startLoading(AsyncOperations.getDetails)),
      switchMap(({ userId }) => this.usersService.getUserDetail(userId)),
      map(
        res =>
          this.toUserAdapter.convert(FromGetUserDetailResponse, res) as User
      ),
      map(user => usersActions.loadUserDetailsSuccess({ user })),
      tap(() => this.usersFacade.endLoading(AsyncOperations.getDetails))
    )
  )

  //#region Add User
  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.addUser),
      map(
        ({ user }) =>
          this.fromUserAdapter.convert(
            ToAddUserRequest,
            user
          ) as AddUserRequestFormData
      ),
      exhaustMap(request =>
        this.usersService.addUser(request).pipe(
          map(() => usersActions.addUserSuccess()),
          catchError(error => of(usersActions.addUserFail({ error })))
        )
      )
    )
  )

  addUserOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.addUserSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `User has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(ADD_USER_DIALOG_ID)?.close()),
      withLatestFrom(this.usersFacade.filters$),
      switchMap(([, filters]) => of(usersActions.loadUsers({ filters })))
    )
  )

  addUserOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.addUserFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to create user."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Update User
  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.updateUser),
      concatLatestFrom(() => [
        this.usersFacade.selectedUser$,
        this.currentUserFacade.allCurrentUserTenants$.pipe(
          map(x => x.map(x => x.id))
        )
      ]),
      map(([{ user }, selectedUser, currentUserTenants]) => {
        const tenantsToRemove = currentUserTenants.filter(
          x => !user.tenants.includes(x)
        )

        const remainingTenants =
          selectedUser?.associatedTenants
            .map(x => x.id)
            .filter(x => !tenantsToRemove.includes(x)) || []

        const tenantsToAdd = [
          ...new Set(remainingTenants?.concat(user.tenants))
        ]

        return { ...user, tenants: tenantsToAdd }
      }),
      map(
        user =>
          this.fromUserAdapter.convert(
            ToUpdateUserRequest,
            user
          ) as UpdateUserRequestFormData
      ),
      exhaustMap(request =>
        this.usersService.updateUser(request).pipe(
          map(() => usersActions.updateUserSuccess()),
          catchError(error => of(usersActions.updateUserFail({ error })))
        )
      )
    )
  )

  updateUserOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.updateUserSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `User has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(() => this.dialog.getDialogById(UPDATE_USER_DIALOG_ID)?.close()),
      withLatestFrom(this.usersFacade.filters$),
      switchMap(([, filters]) => of(usersActions.loadUsers({ filters })))
    )
  )

  updateUserOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.updateUserFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to create user."
          })
        })
      ),
    { dispatch: false }
  )
  //#endregion

  //#region reset password
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.resetPassword),
      tap(() => this.usersFacade.startLoading(AsyncOperations.resetPassword)),
      exhaustMap(request =>
        this.usersService.resetPassword(request.userId).pipe(
          map(() => usersActions.resetPasswordSuccess()),
          catchError(error => of(usersActions.resetPasswordFail({ error }))),
          finalize(() =>
            this.usersFacade.endLoading(AsyncOperations.resetPassword)
          )
        )
      )
    )
  )

  resetPasswordOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.resetPasswordFail),
        tap(() => {
          this.alertsFacade.addAlert({
            label: "There's an error trying to send reset password request.",
            alertType: 'danger'
          })
        })
      ),
    { dispatch: false }
  )

  resetPasswordOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.resetPasswordSuccess),
        tap(() => {
          this.alertsFacade.addAlert({
            label: 'Reset password request has been sent.',
            alertType: 'success'
          })
        }),
        tap(() => this.dialog.getDialogById(RESET_PASSWORD_DIALOG_ID)?.close())
      ),
    { dispatch: false }
  )
  //#endregion

  //#region Generate User's Api Key
  generateApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.generateApiKey),
      tap(() => this.usersFacade.startLoading(AsyncOperations.generateApiKey)),
      exhaustMap(({ userUuid }) =>
        this.usersService.generateApiKeyAccess(userUuid).pipe(
          map(temporalApiKey =>
            usersActions.generateApiKeySuccess({
              userUuid,
              temporalApiKey
            })
          ),
          catchError(({ error }) =>
            of(usersActions.generateApiKeyFail({ error }))
          ),
          finalize(() =>
            this.usersFacade.endLoading(AsyncOperations.generateApiKey)
          )
        )
      )
    )
  )

  generateApiKeyFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.generateApiKeyFail),
        tap(({ error }) => {
          this.dialog
            .getDialogById(USER_GENERATED_API_KEY_DIALOG_ID)
            ?.close({ fromError: true })
          if (error.message) {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: error.message
            })
          } else {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: `There's an error trying to generate an API Key.`
            })
          }
        })
      ),
    { dispatch: false }
  )
  //#endregion
}
