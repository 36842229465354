import { Injectable, inject } from '@angular/core'

import { UserForm, UserFormControls } from '../domain/user-form.model'
import { NavixValidators } from '@navix/forms'
import { NonNullableFormBuilder, Validators } from '@angular/forms'
import { User } from '../domain/user.model'

@Injectable({
  providedIn: 'root'
})
export class UserFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getUserForm() {
    return this.fb.group<UserFormControls>({
      firstName: this.fb.control('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      lastName: this.fb.control('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      roles: this.fb.control([], Validators.required),
      phoneNumber: this.fb.control(''),
      email: this.fb.control('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(256)
      ]),
      timezone: this.fb.control(undefined, Validators.required),
      tenants: this.fb.control([], Validators.required),
      isActive: this.fb.control(true),
      avatar: this.fb.control(undefined, NavixValidators.maxFileSize(64)),
      allowAllTenant: this.fb.control(null),
      userClass: this.fb.control(undefined)
    })
  }

  getUserFormFromUser(user: User): UserForm {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles.map(role => role.id),
      phoneNumber: user.phoneNumber ?? '',
      email: user.email,
      timezone: user.timezoneId,
      tenants: user.associatedTenants.map(tenant => tenant.id),
      isActive: user.isActive,
      avatar: user.avatarFile,
      allowAllTenant: user.allowAllTenantsApiAccess,
      userClass: user.userClass
    }
  }
}
