import { GetUserDetailResponse } from '../domain/get-user-detail.response'
import { User } from '../domain/user.model'
import { IStrategy } from '@navix/utils/adapter'

export class FromGetUserDetailResponse
  implements IStrategy<GetUserDetailResponse, User>
{
  convert(response: GetUserDetailResponse): User {
    return {
      id: response.userGuid,
      secondaryId: response.UserId,
      firstName: response.firstName,
      lastName: response.lastName,
      isActive: response.isActive,
      email: response.email,
      phoneNumber: response.phoneNumber,
      timezone: response.timezoneDisplayName,
      timezoneId: response.timeZoneId,
      timezoneShort: response.timezoneStandardName,
      userClass: response.userClass,
      createdDate: response.createdAt,
      roles: response.roles.map(role => ({
        id: role.rolId,
        description: role.name
      })),
      associatedTenants: response.tenants.map(tenant => ({
        id: tenant.tenantId,
        description: tenant.name
      })),
      avatarUrl: response.avatarUrl,
      fullName: `${response.firstName} ${response.lastName}`,
      avatarLetter: response.firstName
        ? response.firstName[0]
        : response.email[0],
      withDetails: true,
      title: response.title,
      hasApiKeyAccess: response.hasApiKeyAccess,
      temporalApiKey: null,
      allowAllTenantsApiAccess: response.allowAllTenantsApiAccess
    }
  }
}
