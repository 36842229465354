import { IStrategy } from '@navix/utils/adapter'
import { AddUserRequestFormData } from '../domain/add-user.request'
import { User } from '../domain/user.model'
import { UserForm } from '../domain/user-form.model'

export class ToAddUserRequest
  implements IStrategy<User | UserForm, AddUserRequestFormData>
{
  convert(user: UserForm): AddUserRequestFormData {
    const formData: AddUserRequestFormData = []

    formData.push({ key: 'FirstName', value: user.firstName })
    formData.push({ key: 'LastName', value: user.lastName })
    formData.push({
      key: 'TimeZoneId',
      value: (user.timezone as number).toString()
    })
    formData.push({ key: 'Email', value: user.email })
    formData.push({ key: 'IsActive', value: `${user.isActive}` })
    formData.push({
      key: 'UserClass',
      value: ((user.userClass as number) ?? 0).toString()
    })

    for (const roleId of user.roles) {
      formData.push({ key: 'Roles', value: roleId.toString() })
    }

    for (const tenantId of user.tenants) {
      formData.push({ key: 'Tenants', value: tenantId.toString() })
    }

    if (user.phoneNumber)
      formData.push({ key: 'PhoneNumber', value: user.phoneNumber })
    if (user.avatar)
      formData.push({ key: 'AvatarContent', value: user.avatar as File })

    return formData
  }
}
