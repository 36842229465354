export type FilterOption = {
  id: number | string
  label: string
}

export type DataFilters = Partial<{
  search: string
  itemsPerPage: number
  page: number
  status: StatusFilter
  sortBy: string
  tenant: FilterOption[]
  singleRole: boolean
  roles: string[]
  sortDirection: 'asc' | 'desc'
}>

export enum StatusFilter {
  all,
  active,
  inactive
}
