import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'

import { DataFilters } from '../domain/data-filters.model'
import { User } from '../domain/user.model'
import { UsersLoading } from '../domain/users-loading.model'
import { usersActions } from './users.actions'

export const USERS_FEATURE_KEY = 'feature-users' as const

export interface UsersState extends EntityState<User> {
  selectedId?: string | number
  totalCount: number
  loading: UsersLoading
  filters: DataFilters
}

export interface UsersPartialState {
  readonly [USERS_FEATURE_KEY]: UsersState
}

export const usersAdapter: EntityAdapter<User> = createEntityAdapter<User>()

export const initialUsersState: UsersState = usersAdapter.getInitialState({
  totalCount: 0,
  loading: {
    add: false,
    update: false,
    getDetails: false,
    resetPassword: false,
    generateApiKey: false,
    getAll: false
  },
  filters: {
    search: undefined,
    page: 1,
    itemsPerPage: 50,
    sortBy: 'LastName',
    sortDirection: 'asc',
    tenant: [],
    useCurrentTenantWithoutPermissionsRestriction: false
  }
})

const reducer = createReducer(
  initialUsersState,
  on(
    usersActions.loadUsersSuccess,
    (state, { users }): UsersState => usersAdapter.setAll(users, state)
  ),
  on(
    usersActions.loadUserDetails,
    (state, { userId }): UsersState => ({ ...state, selectedId: userId })
  ),
  on(
    usersActions.loadUserDetailsSuccess,
    (state, { user }): UsersState => usersAdapter.upsertOne(user, state)
  ),
  on(
    usersActions.setTotalCount,
    (state, { count }): UsersState => ({
      ...state,
      totalCount: count
    })
  ),
  on(
    usersActions.setLoading,
    (state, { operation, loading }): UsersState => ({
      ...state,
      loading: { ...state.loading, [operation]: loading }
    })
  ),
  on(
    usersActions.setListFilters,
    (state, { filters }): UsersState => ({
      ...state,
      filters
    })
  ),
  on(
    usersActions.patchListFilters,
    (state, { filters }): UsersState => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters
      }
    })
  ),
  on(
    usersActions.resetListFilters,
    (state): UsersState => ({
      ...state,
      filters: initialUsersState.filters
    })
  ),
  on(
    usersActions.generateApiKeySuccess,
    (state, { userUuid: id, temporalApiKey }): UsersState =>
      usersAdapter.updateOne(
        { id, changes: { temporalApiKey, hasApiKeyAccess: true } },
        state
      )
  ),
  on(
    usersActions.loadTenantUsersSuccess,
    (state, { users }): UsersState => usersAdapter.setAll(users, state)
  )
)

export function usersReducer(state: UsersState | undefined, action: Action) {
  return reducer(state, action)
}
