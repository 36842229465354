import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DataFilters } from '../domain/data-filters.model'
import { UserForm } from '../domain/user-form.model'
import { User } from '../domain/user.model'
import { AsyncOperations } from '../domain/users-loading.model'

export const usersActions = createActionGroup({
  source: 'Users',
  events: {
    'load users': props<{ filters: DataFilters }>(),
    'load users success': props<{ users: User[] }>(),
    'set total count': props<{ count: number }>(),
    'set list filters': props<{ filters: DataFilters }>(),
    'patch list filters': props<{ filters: DataFilters }>(),
    'reset list filters': emptyProps(),

    'load user details': props<{ userId: string }>(),
    'load user details success': props<{ user: User }>(),

    'add user': props<{ user: UserForm }>(),
    'add user success': emptyProps(),
    'add user fail': props<{ error: string }>(),

    'update user': props<{
      user: UserForm & Pick<User, 'id' | 'title'>
    }>(),
    'update user success': emptyProps(),
    'update user fail': props<{ error: string }>(),

    'reset password': props<{ userId: string }>(),
    'reset password success': emptyProps(),
    'reset password fail': props<{ error: string }>(),

    'generate api key': props<{ userUuid: string }>(),
    'generate api key success': props<{
      userUuid: string
      temporalApiKey: string
    }>(),
    'generate api key fail': props<{ error: { message: string } }>(),

    'set loading': props<{ operation: AsyncOperations; loading: boolean }>(),
    'load tenant users': props<{ filters: DataFilters }>(),
    'load tenant users success': props<{ users: User[] }>()
  }
})
